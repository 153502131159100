<script lang="ts" context="module">
  type OS = 'mac' | 'linux' | 'windows';

  export type Commands = Record<OS, string>;
</script>

<script lang="ts">
  import { twMerge } from 'tailwind-merge';
  import CommandBlock from '../command-block/command-block.svelte';

  export let commands: Commands | undefined = undefined;
  let os: OS = 'mac';
</script>

<div
  class={twMerge(
    'rounded-lg bg-blue-gray-900 py-6 px-4 flex flex-col gap-4',
    $$props.class,
  )}
>
  <div class="os-tab-list" role="tablist" aria-label="OS Tabs">
    <button
      role="tab"
      aria-selected={os === 'mac'}
      class:selected={os === 'mac'}
      on:click={() => (os = 'mac')}
      class="os-tab">macOS</button
    >
    <button
      role="tab"
      aria-selected={os === 'windows'}
      class:selected={os === 'windows'}
      on:click={() => (os = 'windows')}
      class="os-tab">Windows</button
    >
    <button
      role="tab"
      aria-selected={os === 'linux'}
      class:selected={os === 'linux'}
      on:click={() => (os = 'linux')}
      class="os-tab">Linux</button
    >
  </div>
  {#if commands && commands[os]}
    <CommandBlock content={commands[os]} />
  {/if}
  <slot {os} />
</div>

<style lang="postcss">
  .os-tab-list {
    @apply flex flex-row gap-5;
  }

  .os-tab {
    @apply border-0 appearance-none cursor-pointer font-sans text-2xl text-blue-gray-500;

    &.selected {
      @apply underline underline-offset-2 text-white;
    }
  }
</style>
